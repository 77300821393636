import React, { useEffect, useState } from "react";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import { loadWeb3 } from "../../Utility/contract";
import toast from "react-hot-toast";
import { updateAuth } from "../../Redux/AuthSlice";
import { API } from "../../Utility/API";
import { useDispatch } from "react-redux";
import axios from "axios";

export const Login = () => {
  let navigate = useNavigate();
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const [LoginId, setLoginId] = useState("");
  const [connected, setconnected] = useState(true);
  const [ipAddress, setIP] = useState("");
  const dispatch = useDispatch();

  async function IpAddressAPI() {
    try {
      let response = await axios.get("https://geolocation-db.com/json/");
      setIP(response.data.IPv4);
    } catch (e) {
      console.log("Error in IP API", e);
    }
  }
  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();

      if (acc === "No Wallet" || acc === undefined) {
        toast.error("No Wallet");
        setconnected("Wallet is locked");
      } else if (acc === "Wrong Network") {
        toast.error(" Wrong Network Please Connect SKH Network");
        console.log("Wrong Network Please Connect SKH Scan Network");
        setconnected("Wrong Network");
      } else {
        // setaddress(acc)
        setconnected("Wallet is Connected");
        // setLoginId("0X484Demo848sdcssd48");
        setLoginId(acc);

        if (acc !== "") {
          let res = await API.post(`/login`, {
            password: acc,
            ipaddress: ipAddress,
          });
          // console.log("UserloginNew Auto", res);

          if (res.data.data.result === "Successfull") {
            toast.success(`Login Successful`);
            // console.log("loginmask", res.data.token);
            dispatch(
              updateAuth({
                isAuth: true,
                userId: res.data.data.uid_output,
                jwtToken: res.data.token,
                ipAddress: ipAddress,
              })
            );
            navigate("/dashboard");
          } else {
            toast.error(res.data.data.result);
          }
        }
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  // const onSubmitHandler = async () => {
  //   try {
  //     let res = await API.post(`/login`, {
  //       password: LoginId,
  //       ipaddress: ipAddress,
  //     });

  //     if (res.data.data.result === "Successfull") {
  //       toast.success(res.data.data.result);
  //       // console.log("login" , res );
  //       dispatch(
  //         updateAuth({
  //           isAuth: true,
  //           userId: res.data.data.uid_output,
  //           jwtToken: res.data.token,
  //           ipAddress: ipAddress,
  //         })
  //       );
  //       navigate("/Dashboard");
  //     } else {
  //       toast.error(`Invalid User id or Address`);
  //     }
  //   } catch (e) {
  //     console.error("Error in Login API", e);
  //   }
  // };

  useEffect(() => {
    localStorage.clear();
    IpAddressAPI();
    walletConnected();
    const IntervalSession = setInterval(() => {
      walletConnected();
    }, 10000);
    return () => clearInterval(IntervalSession);
  }, []);
  return (
    <div>
      <div className="Login_bg">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-3" />
              <div className="col-md-6">
                <div>
                  <div id="reg-layout">
                    <div className="Toastify" />
                    <div className="reg-box">
                      <a href="/">
                        <div className="reg-label" />
                      </a>
                      <br />
                      <div className="reg-content">
                        <div className="subtitle">
                          Access your personal account
                        </div>
                        <span id="lock" style={{ display: "none" }} />
                        <span
                          id="metamaskConnection"
                          style={{
                            color: "green",
                            fontWeight: 600,
                            fontSize: 16,
                          }}
                        >
                          {connected}
                        </span>
                        <button
                          className="btn btn-secondary"
                          disabled
                        // onClick={() => {
                        //   onSubmitHandler();
                        // }}
                        >
                          Authorized Login
                        </button>
                        <p className="note">
                          Remember to authorize with the correct address.
                        </p>
                        <div className="subtitle">View an Account</div>
                        <form>
                          <div className="form-row">
                            <input
                              type="text"
                              maxLength={100}
                              placeholder="Please enter ID or address cxcxcxzc"
                              className="form-input"
                              value={LoginId}
                              //onChange={(e) => setLoginId(e.target.value)}
                            //readOnly
                            />
                            <p className="note">
                              Enter the account id or BBT address
                            </p>
                          </div>
                          {/* <Link to="/Dashboard" className="w-100"> */}
                          <div
                            className="form-row "
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <input
                              type="button"
                              className="btn btn-secondary"
                              id="btnlogin"
                              defaultValue="Login"
                            />
                          </div>
                          {/* </Link> */}
                        </form>
                        <p className="loginagain">
                          Don’t have an account?
                          <Link
                            to="/Register"
                            style={{ color: "rgb(255, 255, 255)" }}
                          >
                            Register
                          </Link>
                        </p>
                        <div className="footer-section2">
                          Copyright © 2024 Stakebbt . All Rights
                          Reserved.&nbsp;&nbsp;&nbsp;| &nbsp;&nbsp;&nbsp;
                          <a
                            rel="noopener noreferrer"
                            title="BNB Smart Contract"
                            style={{ color: "rgb(255, 255, 255)" }}
                          >
                            BBT Smart Contract
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
