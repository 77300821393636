import React, { useRef, useState, useEffect } from "react";
import DashHeader from "../DashHeader/DashHeader";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../Utility/API";
import { updateAuth } from "../../Redux/AuthSlice";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";
import {
  ContractAddress_Main,
  Abi_Main,
  ContractAddress_USDT,
  Abi_USDT,
} from "../../Utility/ContractAddress";
import { loadWeb3 } from "../../Utility/contract";
import toast from "react-hot-toast";
import Web3 from "web3";

export const Buy = () => {
  const dispatch = useDispatch();
  const { userId, jwtToken } = useSelector((state) => state.Auth);
  const [walletAddress, setwalletAddress] = useState("");
  const [USDAmount, setUSDAmount] = useState(0);
  const [TokenAmount, setTokenAmount] = useState(0);
  const [staketime, setstaketime] = useState(0);
  const navigate = useNavigate();
  let [connected, setconnected] = useState("Wallet is locked");
  let [USDTBal, setUSDTBal] = useState(0);
  let [Token1ToUSD, setToken1ToUSD] = useState(0);
  const [loading, setLoading] = useState(false);

  const UserInfo_API = async () => {
    try {
      const bankres = await API?.get(`userDashboard?uid=${userId}`, {
        headers: {
          Authorization: `${jwtToken}`,
          "Content-Type": "application/json",
        },
      });

      setwalletAddress(bankres.data.data[0][0].btcaddress);
      setToken1ToUSD(bankres.data.data[0][0].Token_rate);
    } catch (e) {
      console.log("Error While calling userinfomyReferrals API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();

      if (acc == "No Wallet") {
        setconnected("Wallet is locked");
      } else if (acc == "Wrong Network") {
        toast.error(" Wrong Network Please Connect SKH Network");
        setconnected("Wrong Network");
      } else {
        setconnected("Wallet is Connected");
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const USDTBalance = async () => {
    try {
      let acc = await loadWeb3();
      let contract = await new window.web3.eth.Contract(
        Abi_USDT,
        ContractAddress_USDT
      );
      let result = await contract.methods.balanceOf(acc.toString()).call();
      // console.log("result-->", result);
      result = parseFloat(result) / 10 ** 18;
      // console.log("result-->", parseFloat(result).toFixed(6));
      setUSDTBal(parseFloat(result).toFixed(2));
    } catch (error) {
      console.log(error);
    }
  };

  const CalculateValue = async (val) => {
    if (val === undefined || val === "") {
      setUSDAmount(0);
      setTokenAmount(0);
    } else {
      let value = DOMPurify.sanitize(val);
      value = value.replace(/[^0-9]/gi, "").substring(0, 8);
      const tvalue = parseFloat(value);
      setUSDAmount(tvalue);
      setTokenAmount(tvalue);
    }
  };

  const buyToken = async () => {
    let acc = await loadWeb3();

    setLoading(true);
    if (acc == "No Wallet") {
      toast.error("No Wallet");
      setLoading(false);
    } else if (acc == "Wrong Network") {
      toast.error("Wrong Network Please Connect SKH Scan Network");
      setLoading(false);
    } else {
      if (walletAddress.toUpperCase() == acc.toUpperCase()) {
        if (USDAmount != 0) {
          if (staketime > 0) {
            if (USDTBal < USDAmount) {
              toast.error("Insufficient BBT Balance");
              setLoading(false);
            } else if (parseFloat(USDAmount) < parseFloat(10)) {
              toast.error("you can deposite minimum 10 BBT");
              setLoading(false);
            } else if (parseFloat(USDAmount) > parseFloat(50000)) {
              toast.error("you can deposite maximum 50000 BBT");
              setLoading(false);
            } else {
              // let amountValue = USDAmount * 10 ** 18;
              let amountValue = Web3.utils.toWei(USDAmount, "ether").toString();
              console.log("amountValue--->", amountValue);
              // console.log(Web3.utils.toWei(USDAmount, "ether"))

              const gasPrice = await window.web3.eth.getGasPrice();
              const approveBlock = window.web3.eth.getBlock("latest");

              try {
                let contract_Main = await new window.web3.eth.Contract(
                  Abi_Main,
                  ContractAddress_Main
                );
                let contract_USDT = await new window.web3.eth.Contract(
                  Abi_USDT,
                  ContractAddress_USDT
                );

                let approveCall = await contract_USDT.methods
                  .approve(ContractAddress_Main, amountValue)
                  .send({
                    from: acc,
                    gasLimit: approveBlock.gasLimit,
                    gasPrice: await window.web3.eth.getGasPrice(),
                  });
                toast.success("Approved Succesful");
                let sellCall = await contract_Main.methods
                  .deposit(amountValue)
                  .send({
                    from: acc,
                    gasLimit: approveBlock.gasLimit,
                    gasPrice: await window.web3.eth.getGasPrice(),
                  });

                let res = await API.post(
                  `activationtemp`,
                  {
                    Userid: userId,
                    Address: acc,
                    Amount: USDAmount,
                    Token: TokenAmount,
                    Bnb: 0,
                    Hash: sellCall.transactionHash,
                    staketime: staketime
                  },
                  {
                    headers: {
                      Authorization: `${jwtToken}`,
                      "Content-Type": "application/json",
                    },
                  }
                );

                toast.success("Transaction Succesful");
                if (res.data.success == true) {
                  toast.success("Deposit Successful");
                  // window.location.href = "/Dashboard";
                  navigate("/Dashboard");
                  setLoading(false);
                }
              } catch (err) {
                console.log("error while calling fuction sell", err);
                setLoading(false);
              }
            }
          }
          else {
            toast.error("Please Select Time");
            setLoading(false);
          }

        } else {
          toast.error("Please Enter Amout First");
          setLoading(false);
        }
      } else {
        toast.error("Invalid Wallet address");
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setInterval(() => {
      walletConnected();
      USDTBalance();
    }, 5000);

    UserInfo_API();
  }, []);

  return (
    <div>
      <DashHeader />
      <div className="token-pricing-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4" />
            <div className="col-md-4">
              <div className="timerss jshsa">
                <center>
                  <div className="count-box">
                    <h4 className="text pad btto">Stake BBT</h4>
                    {connected == "Wallet is Connected" ? (
                      <h6
                        className="text-center mb-2"
                        style={{ color: "white" }}
                      >
                        {connected}
                      </h6>
                    ) : (
                      <h6 className=" text-center" style={{ color: "red" }}>
                        {connected}
                      </h6>
                    )}
                    <div className="tab">
                      <button className="tablinks">
                        Balance: {USDTBal} BBT
                      </button>
                    </div>
                    <div
                      id="London"
                      className="tabcontent pad"
                      style={{ display: "block" }}
                    >
                      {/* <h4 className="text-black">
                        1 BBT Token = $ {Token1ToUSD}
                      </h4> */}
                      <div className="dis kijsdiwj">
                        <div>
                          <label className="text-left text-white me-2">
                            <b>BBT Token</b>
                          </label>
                          <input
                            type="text"
                            className="imput"
                            id="myInput"
                            defaultValue={0}
                            value={USDAmount}
                            onChange={(e) => {
                              CalculateValue(e.target.value);
                            }}
                          />
                        </div>
                        <br />
                        <div>
                          <label className="text-left text-white me-2">
                            <b>Select Time</b>
                          </label>
                          <select className="imput" onChange={(e) => setstaketime(e.target.value)}>
                            <option value="0">Select Time</option>
                            <option value="1">1 Year (18%)</option>
                            <option value="2">2 Year (24%)</option>
                            <option value="3">3 Year (30%)</option>
                          </select>

                        </div>
                        {/* <div className="">
                          <label className="text-left text-white">
                            <b>BBT Token</b>
                          </label>
                          <input
                            type="text"
                            className="imput"
                            id="myInput"
                            defaultValue={0}
                            value={TokenAmount}
                          />
                        </div> */}
                      </div>
                      <div className="pad">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => buyToken()}
                          disabled={loading}
                        >
                          {loading ? "Payment Under Process" : "Stake BBT"}
                        </button>
                      </div>
                      {/* <div className="pad bg">
                        <a href="" className="text-black">
                          {" "}
                          <img src="assets/image/bsc.svg" alt="" /> Buy on BSC
                        </a>
                      </div> */}
                    </div>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
